import React,{Suspense} from 'react'

const withLazyComponent = (LazyComponent) => {
  return (props) => (
    <Suspense fallback="Please wait..">
      <LazyComponent {...props} />
    </Suspense>
  )
} 

const Login=withLazyComponent(React.lazy(()=>import('../pages/Login')));
const Dashboard=withLazyComponent(React.lazy(()=>import('../pages/Dashboard')));
const CarpetStock=withLazyComponent(React.lazy(()=>import('../pages/CarpetStock')));
const RugStock=withLazyComponent(React.lazy(()=>import('../pages/RugStock')));


const routes = [
  {
    path: '/',
    exact: true,
    auth: true,
    component: Dashboard,
    fallback: Login,
  },
  {
    path: '/login',
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path:'/carpet-stock',
    exact:true,
    auth:true,
    component: CarpetStock
  },
  {
    path:'/rug-stock',
    exact:true,
    auth:true,
    component: RugStock
  },
  {
    path: '',
    exact: false,
    auth: false,
    component: Login,
  },
];

export default routes;
