import React from 'react';
import * as nav from '../../config/navigation';
import { Menubar } from 'primereact/menubar';
import {Button} from 'primereact/button';
import { useHistory } from "react-router-dom";




const SUPER_USER='Group IT manager';

const Navigation = (props) => {

    const history=useHistory();
    
    const user=JSON.parse(localStorage.getItem('user'));
    const authToken=sessionStorage.getItem('access_token');
    /**
     * Adds command menthod that routes the url with react-router
     * @param Array menu Menu item config array
     * @returns Menu Array with routing
     */
    const getMenuObject=(menu)=>{
        let menuObj={};
        menuObj.label=menu.label;

        if(menu.items){
            menuObj.items=menu.items.map(nestedItem=>{
                return getMenuObject(nestedItem);
            });  
        }
        if(menu.icon){
            menuObj.icon=menu.icon;
        }
        if(menu.url){
            menuObj.command=()=>{
               navigatePage(menu.url);
            }
        }
        return menuObj;
    }

    const navigationMenu=nav.navitems.map(menuItem=>{
        return getMenuObject(menuItem);
    })
    
    const navigatePage=(url)=>{
        history.push(url);
    }

    const endTemplate=()=>{
    
        if(user.name===SUPER_USER){
            return (<>
            <Button className="p-button-secondary" onClick={()=>props.onRedirect(authToken)} label="Administration"  icon="pi pi-cog"/> {' '}
            <Button label={'Logout ('+user.username+')'}  onClick={(e)=>props.onLogout(e)} icon="pi pi-power-off"/>
            </>
            )
        } else {
            return <Button label={'Logout ('+user.username+')'}  onClick={(e)=>props.onLogout(e)} icon="pi pi-power-off"/>;
        }

    }
    return (
        <Menubar 
        model={navigationMenu}
        end={endTemplate}
        />
    );
}

export default Navigation;
