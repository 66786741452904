import React, { Component } from 'react';
import Navigation from './Navigation';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import * as env from '../../env';


class Header extends Component {
  onLogout = (e) => {
    e.preventDefault();
    this.props.dispatch(actions.authLogout());
  }

  redirectAdmin=(authToken)=>{
    window.open(env.API_END_POINT+'auth/login-desktop?token='+authToken, '_blank');
  }

  render() {
    return (
      <div>
        {this.props.isAuthenticated && <Navigation onLogout={(e) => this.onLogout(e)} onRedirect={(authToken)=>this.redirectAdmin(authToken)} />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(Header);
