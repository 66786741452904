import axios from 'axios';
import * as env from './env';

const token = sessionStorage.getItem('access_token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.baseURL= env.API_END_POINT+env.API_DIR;


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('Unauthorized');
    }
    return Promise.reject(error);
  },
);

export default axios;
