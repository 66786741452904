import * as ActionTypes from '../action-types';

const initialState={
    searchCriteria:{}
}

const searchCriteria=(state,payload)=>{
    const stateObj={
        ...state,
        searchCriteria:payload
    }
    return stateObj;
}

const rugDataReducer=(state=initialState,{type,payload=null})=>{
    switch(type){
        case ActionTypes.SEARCH_CRITERIA_RUG:
            return searchCriteria(state,payload);
        default:
            return state;
    }
}

export default rugDataReducer;