export const navitems = [
    {
        label: 'Home',
        icon:'pi pi-fw pi-home',
        url: '/'
    },
    {
        label: 'Carpet Stock',
        icon:'pi pi-fw pi-table',
        url: '/carpet-stock'
    },
    {
        label: 'Rug Stock',
        icon:'pi pi-fw pi-money-bill',
        url: '/rug-stock'
    },
   
]
