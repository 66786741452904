import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import * as action from './store/actions';

store.dispatch(action.authCheck());

const App = () => {
  return (
    <Provider store={store} >
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </Provider >
  );
}

export default App;
